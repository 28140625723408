.loading {
  &__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    z-index: 200;
    text-align: center;
    text-wrap: nowrap;
  }

  &__dot {
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}

@include mobile {
  .loading {
    &__wrapper {
      padding: 10px;
      width: calc(100% - 40px);
      text-align: center;
    }
  }
}
