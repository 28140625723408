.interface {
  &__header-wrapper {
    position: fixed;
    margin: 20px;
    top: 0;
    left: 0;
    padding: 10px 20px;
    z-index: 20000;
  }
  &__nav-wrapper {
    margin: 10px 20px;
    position: fixed;
    top: calc(3vw + 40px);
    left: 0;
    z-index: 99;
    transition: opacity 0.2s ease-in-out;
    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__nav-button {
    padding: 5px 10px 3px 10px;
    margin-right: 10px;
    text-decoration: none;
    display: inline-block;
    // a{
    //   display: inline-block;
    // }
  }

  &__container {
    width: 100%;
    height: var(--app-height);
    overflow: auto;
    position: absolute;
    cursor: grab;
    // position: fixed;
    top: 0;
    left: 0;
    // background-color: green;
    transform-origin: top left;
    // transition: all 0.4s ease-in-out;
    &:active{
      cursor: grabbing;
    }
    // transform: scale(0.2);
  }

  &__outer-wrapper {
    // width: 100%;
    // height: 100%;
    width: 600vw;
    height: 600vh;
    // margin: 50vh 50vw;
    position: relative;
    // background-color: blue;
    transform-origin: top left;

    // transition: all 0.4s ease-in-out;

    // overflow: hidden;
    // transition: all 0.4s ease-in-out;
  }

  &__entries-wrapper {
    // width: 100%;
    // height: 100%;
    width: 600vw;
    height: 600vh;
    // padding: 50vh 50vw;
    position: absolute;
    overflow: visible;
    transform-origin: top left;
    z-index: 10;
    // transition: all 0.4s ease-in-out;

    // background-color: red;
    // transition: all 0.4s ease-in-out;
  }

  &__connections-wrapper {
    // width: 100%;
    // height: 100%;
    width: 600vw;
    height: 600vh;
    position: absolute;
    // top: -50vh;
    // left: -50vw;
    overflow: visible;
    transform-origin: top left;
    // transition: all 0.4s ease-in-out;
    // background-color: red;
    // background-color: red;
    // transition: all 0.4s ease-in-out;
    pointer-events: none;
    &.disabled {
      opacity: 0;
    }
  }

  &__zoom-wrapper {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 20px 0;
    z-index: 200;
  }

  &__zoom-button {
    padding: 10px;
    margin: 0 5px;
    text-transform: capitalize;
    opacity: 0.3;
    pointer-events: none;
    &.active{
      opacity: 1;
      pointer-events: all;
    }
  }
}

@include mobile {


  .interface{
    &__header-wrapper {
      padding: 10px;
      width: calc(100% - 61px);
      text-align: center;
    }

    &__nav-wrapper {
      top: calc(6vw + 40px);
      width: calc(100% - 39px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.disabled {
        opacity: 1;
        pointer-events: all;
      }
    }

    &__nav-button {
      padding: 5px 10px 3px 10px;
      margin-right: 0;
      // h3{
      //   font-size: 4vw;
      //   line-height: 4vw;
      // }
    }

  }

}