.entry{

    &__outer-wrapper{
        position: absolute;
        padding: 5px;
        background-color: #fff;
        border: 1px solid #000;
        z-index: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        text-wrap: nowrap;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
        &.active{
            background-color: #abff61;
        }
        // &.highlight{
        //     opacity: 1 !important;
        // }
        &.disabled{
            opacity: 0;
            pointer-events: none;
        }
        &.opacity{
            opacity: 0.3;
        }
        &.opacity.disabled{
            opacity: 0;
            pointer-events: none;
        }
        &.opacity.highlight{
            opacity: 1 !important;
            pointer-events: all;
        }
        &.opacity.highlight.disabled{
            opacity: 0 !important;
            pointer-events: none;
        }
        &::before{
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #000;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -2px;
            z-index: 0;
        }
        &::after{
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #000;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -2px;
            z-index: 0;
        }
    }

    &__click-wrapper{
        display: flex;
        flex-direction: row;
    }

    &__icon-wrapper{
        margin: 0 5px;
        img{
            width: 14px;
            height: 14px;
            display: block;
            height: 100%;
        }
    }

    &__drag-button{
        height: 100%;
        width: 10px;
        padding: 2px 5px 2px 5px;
        margin-left: 5px;
        cursor: grab;
        &:active{
          cursor: grabbing;
        }
        img{
            display: block;
            height: 100%;
            width: auto;
        }
    }
}