.connections{

    &__wrapper{
        // width: 500vw;
        // height: 500vh;
        width: 100%;
        height: 100%;
        // background-color: red;
        position: absolute;
        pointer-events: none;
        // background-color: red;
    }

    &__line{
        stroke-width: 0.5;
        &.ref{
            stroke: #047d1c;
        }
        &.track{
            stroke: #a023e8;
        }
        &.parent{
            stroke: #bf2802;
        }
    }
}