@font-face {
  font-family: 'ABCDiatype-Medium';
  src: url('../assets/fonts/ABCDiatype-Medium.woff2') format('woff2'),
      url('../assets/fonts/ABCDiatype-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCDiatype-Heavy';
  src: url('../assets/fonts/ABCDiatype-Heavy.woff2') format('woff2'),
      url('../assets/fonts/ABCDiatype-Heavy.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


html,
body {
  font-family: 'ABCDiatype-Medium', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  // text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.5px;
}

h1{
  font-size: 3vw;
  line-height: 3vw;
  letter-spacing: -0.2vw;
}

h2{
  font-size: 2vw;
  line-height: 2vw;
  letter-spacing: -0.1vw;
}

h3{
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -1px;
}

.bold{
  font-family: 'ABCDiatype-Heavy', sans-serif;
}

a {
  color: inherit;
  // text-decoration: none;
  @include hover {
    // text-decoration: none;
  }
}

p{
  margin-bottom: 10px;
}
em {
  font-style: italic;
}

strong {
  font-weight: bold;
}


@include mobile {


  h1{
    font-size: 6vw;
    line-height: 6vw;
  }

  h2{
    font-size: 4vw;
    line-height: 4vw;
  }

  h3{
    font-size: 4vw;
    line-height: 4vw;
  }

}
