.spotify{

    &__container{
        position: fixed;
        margin: 0 0 20px 20px;
        transition: bottom 0.4s ease-in-out;
        bottom: -192px;
        z-index: 200;
        &.active{
            bottom: 0;
        }
    }
    &__close-button{
        right: -35px;
        top: -3px !important;
      }
    &__outer-wrapper{
        width: calc(30vw - 20px);
        height: 152px;
        overflow: hidden;
    }

    &__loading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }

    &__embed{
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
        z-index: 3;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }
}

@include mobile {

    .spotify{

        &__container{
            bottom: -192px;
            z-index: 100;
            width: calc(100% - 70px);
            &.active{
                bottom: calc(4vw + 30px);
            }
        }

        &__outer-wrapper{
            width: 100%; 
        }
    }
}