.info {
  &__close-button {
    right: 3px;
    z-index: 10;
  }

  &__container {
    left: calc(-30vw - 40px);
    top: calc(40px + 3vw);
    width: calc(30vw - 20px);
    height: calc(var(--app-height) - 3vw - 70px);
    margin: 10px 0 10px 20px;
    position: fixed;
    transition: height 0.4s ease-in-out, left 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    z-index: 100;
    &.info-open {
      left: 0;
    }
    &.spotify-open {
      height: calc(var(--app-height) - 232px - 3vw);
    }
  }
  &__outer-wrapper {
    padding: 20px 0 20px 20px;
    height: 1px;
    flex-grow: 1;
  }

  &__inner-wrapper {
    padding: 20px 17px 20px 0;
    overflow-y: auto;
    margin-top: calc(2vw + 5px);
    height: calc(100% - 2vw - 59px);
  }

  &__title-wrapper {
    width: calc(100% - 40px);
    padding: 15px 0 10px 0;
    border-bottom: 1px solid #000;
    position: absolute;
    top: 0;
    z-index: 10;
  }

  &__artwork{
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 5px;
  }

  &__credit{
    width: calc(100% - 60px);
    padding: 10px;
    margin: 0 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 0.5px solid #000;
    text-align: center;
  }
}

@include mobile {

  .info {
    &__container {
      left: calc(-100vw - 40px);
      top: calc(10vw + 59px);
      width: calc(100% - 39px);
      height: calc(var(--app-height) - 14vw - 120px);
      z-index: 100;
      &.spotify-open {
        height: calc(var(--app-height) - 287px - 14vw);
      }
    }

    &__inner-wrapper {
      margin-top: calc(4vw + 6px);
      height: calc(100% - 4vw - 60px);
    }
  }
}