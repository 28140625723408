.key {
  &__close-button {
    right: 3px;
    z-index: 10;
  }

  &__container {
    left: calc(-30vw - 40px);
    top: calc(40px + 3vw);
    width: calc(30vw - 20px);
    height: calc(var(--app-height) - 3vw - 70px);
    margin: 10px 0 10px 20px;
    position: fixed;
    transition: height 0.4s ease-in-out, left 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    z-index: 100;

    &.key-open {
      left: 0;
    }
    &.spotify-open {
      height: calc(var(--app-height) - 232px - 3vw);
    }
  }
  &__outer-wrapper {
    padding: 20px 0 20px 20px;
    height: 1px;
    flex-grow: 1;
  }

  &__inner-wrapper {
    padding: 20px 17px 20px 0;
    overflow-y: auto;
    margin-top: calc(2vw + 5px);
    height: calc(100% - 2vw - 25px);
  }

  &__title-wrapper {
    width: calc(100% - 40px);
    padding: 15px 0 10px 0;
    border-bottom: 1px solid #000;
    position: absolute;
    top: 0;
    z-index: 10;
  }

  &__section-wrapper {
    margin-bottom: 40px;
    width: 100%;
  }

  &__section-title {
    margin-bottom: 10px;
  }

  &__entry-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__icon-wrapper {
    min-width: 30px;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }

  &__entry-text {
    height: 30px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    &.connection {
      padding-left: 0;
      flex-grow: 0;
    }
  }

  &__entry-line {
    height: 14px;
    margin: 0 10px;
    border-bottom: 1px solid;
    flex-grow: 1;
    &.ref {
      border-color: #047d1c;
    }
    &.track {
      border-color: #a023e8;
    }
    &.parent {
      border-color: #bf2802;
    }
  }

  &__toggle-wrapper {
    min-width: 60px;
    height: 20px;
    background-color: #ccc;
    border-radius: 30px;
    box-shadow: inset 0 0 10px #33333380;
    border: 0.5px solid #000;
    position: relative;
    overflow: hidden;
    margin-top: 5px;
  }
  &__toggle-green {
    width: 30px;
    height: 100%;
    background-color: #abff61;
    box-shadow: inset 0 0 10px #33333380;
  }

  &__toggle-button {
    width: 40px;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 10px;
    transition: left 0.2s ease-out;
    box-shadow: 0px 0px 6px 3px #00000080;
    &.active {
      left: 20px;
    }
  }
}

@include mobile {

  .key{

    &__container {
      left: calc(-100vw - 40px);
      top: calc(10vw + 59px);
      width: calc(100% - 39px);
      height: calc(var(--app-height) - 14vw - 120px);
      z-index: 100;
      &.spotify-open {
        height: calc(var(--app-height) - 287px - 14vw);
      }
    }

    &__inner-wrapper {
      padding: 20px 17px 20px 0;
      overflow-y: auto;
      margin-top: calc(4vw + 6px);
      height: calc(100% - 4vw - 26px);
    }

  }
}