.preloader {
  &__wrapper {
    background-color: #eeeeee;
    width: 100%;
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__button {
    padding: 10px 20px;
    display: inline !important;
    margin-top: 1vw;
  }

  &__mouse-wrapper {
    height: 6vw;
    width: 4vw;
    border: 0.5px solid #000;
    border-radius: 10px 10px 0 0;
    margin: 0 auto 2vw auto;
    position: relative;
    animation: mouse-move 1s linear infinite alternate;
  }

  &__mouse-button {
    width: calc(50% - 1px);
    position: absolute;
    top: 0;
    height: 40%;
    &.left {
      left: 0;
      border-radius: 10px 0 0 0;
      border-bottom: 0.5px solid #000;
      border-right: 0.5px solid #000;
      animation: mouse-click 2s ease-in-out infinite;
    }
    &.right {
      right: 0;
      border-radius: 0 10px 0 0;
      border-bottom: 0.5px solid #000;
    }
  }

  &__arrow-wrapper{
    position: absolute;
    width: 15px;
    height: 15px;
    top: calc(50% - 7.5px);
    &.left {
        left: -30px;
      }
      &.right {
        right: -30px;
      }
  }

  &__arrow{
    display: block;
    width: 100%;
    height: 100%;
  }
}

@include mobile {

  .preloader{

    &__mouse-wrapper {
      height: 15vw;
      width: 20vw;
      border-top: none;
      border-radius: 0 0 50px 50px;
      margin: 0 auto 5vw auto;
    }

    &__mouse-button {
     display: none;
    }

    &__finger-wrapper{
      height: 15vw;
      width: calc(20vw + 1px);
      position: absolute;
      bottom: 100%;
      left: -0.5px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &__finger{
      width: 25%;
      height: 20%;
      border: 0.5px solid #000;
      border-bottom: none;
      // margin-top: calc(7vw + 1px);
      margin-top: 60%;

      border-radius: 15px 15px 0 0;
      &.index{
        height: 100%;
        margin-top: 0;
      }     
    }

    
    &__button {
      margin-top: 20px;
    }
  }
}
@keyframes mouse-move {
    0% {
        left: -2vw;
        top: 0;
        transform: rotate(-20deg);
    }
    50% {
        left: 0;
        top: -0.5vw;
        transform: rotate(0deg);

    }
    100% {
        left: 2vw;
        top: 0;
        transform: rotate(20deg);

    }
  }

@keyframes mouse-click {
    0% {
      background-color: #000000;
    }
    50% {
      background-color: #00000000;
    }
    100% {
      background-color: #000000;
    }
  }