*::selection {
  background: #fff;
  color: #000;
}
*::-moz-selection {
  background: #fff;
  color: #000;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  // display: none;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
  // display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  // border: none;
}

// @include mobile {
//   *::-webkit-scrollbar {
//     display: none;
//   }
//   *::-webkit-scrollbar-track {
//     display: none;
//   }
  
//   *::-webkit-scrollbar-thumb {
//     border: none;
//   }
// }

html,
body {
  width: 100vw;
  height: var(--app-height);
  overflow: auto;
  background-color: #eee;
}

.container {
  background-color: #ffffff30;
  backdrop-filter: blur(5px);
  border: 1px solid #BBB;
  &.spotify{
    border-radius: 14px;
  }
}

.close-button {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 5px;
  overflow: hidden;
  position: absolute;
  top: 3px;
  background-image: url("../assets/icons/close.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 70%;
}

.zoom-in {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 2000;
}
.zoom-out {
  position: fixed;
  bottom: 20px;
  left: 200px;
  z-index: 2000;
}

@include mobile {
  .container {
    background-color: #ffffff80;
  }

}