.content {
  &__close-button {
    right: 0;
    z-index: 10;
  }

  &__container {
    position: fixed;
    margin: 20px;
    width: calc(30vw - 20px);
    right: calc(-30vw - 100px);
    height: calc(var(--app-height) - 40px);
    transition: right 0.4s ease-in-out, left 0.4s ease-in-out;
    z-index: 200;
    display: flex;
    flex-direction: column;
    &.active {
      right: 0;
    }
  }

  &__title-wrapper {
    width: calc(100% - 20px);
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__history-wrapper{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__history-button{
    display: block;
    // text-align: center;
    width: calc(50% - 5.5px);
    padding: 5px;
    position: relative;
    &.disabled{
      pointer-events: none;
      color: #BBB;
    }
    &.prev{
      text-align: right;
    }
    &.next{
      text-align: left;
    }
  }

  &__history-arrow{
    padding: 5px;
    height: calc(100% - 10px);
    position: absolute;
    top: 0;
    // height: 5px;
    img{
      display: block;
      height: 100%;
      width: auto;
    }
    &.disabled{
      opacity: 0.3;
    }
    &.next{
      right: 5px;
    }
  }

  &__content-outer-wrapper {
    padding: 0 0 20px 20px;
    flex-grow: 1;
    height: 1px;
  }

  &__content-inner-wrapper {
    padding: 20px 0;
    height: calc(100% - 20px);
    overflow-y: auto;
  }

  &__section-title {
    margin-bottom: 15px;
  }

  &__section-wrapper {
    display: block;
    height: auto;
    padding-bottom: 10px;
    margin: 0 17px 20px 0;
    border-bottom: 0.5px solid #000;

    img{
      margin-bottom: 10px;
    }

    &.youtube {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      border-bottom: none;
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__icon-wrapper {
    width: 33px;
    height: 33px;
    overflow: hidden;
  }
  &__title {
    margin-left: 10px;
    flex: 1;
    padding-right: 24px;
  }

  //ARTWORK
  &__artwork {
    display: block;
    width: 100%;
    height: auto;
  }

  &__connections-wrapper {
    margin-bottom: 10px;
  }

  &__connection-wrapper {
    width: 100%;
    display: block;
    text-align: left;
    padding: 0;
    margin-bottom: 5px;
  }

  &__connection-ref-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 20px;
    position: relative;
    @include hover{
      background-color: #DDD;
    }
    &::after{
      content: '';
      height: 22px;
      width: 20px;
      position: absolute;
      right: 0;
      margin-top: 16px;
      background-image: url("../../assets/icons/next.svg");
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
    }
  }

  &__connection-spotify-wrapper{
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    width: 100%;
    @include hover{
      background-color: #DDD;
    }
  }

  &__connection-icon {
    width: 20px;
    height: 20px;
    padding-top: 15px;
    &.no-padding {
      padding-top: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__connection-text {
    margin-left: 10px;
    text-align: left;
    flex: 1;
    
  }

  //TRACKS
  &__connection-track-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 20px 0 0;
    margin-bottom: 5px;
    text-align: left;
    position: relative;
    @include hover{
      background-color: #DDD;
    }
    &::after{
      content: '';
      height: 22px;
      width: 20px;
      position: absolute;
      right: 0;
      background-image: url("../../assets/icons/next.svg");
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
    }
  }

  &__connection-track-index {
    width: 40px;
  }
  &__connection-track-title {
    flex: 1;
    // text-align: left;
  }
}

@include mobile {

  .content{

    &__container {
      width: calc(100% - 40px);
      right: calc(-100% - 100px);
      top: calc(10vw + 49px);
      height: calc(var(--app-height) - 14vw - 120px);
      z-index: 99;
      transition: right 0.4s ease-in-out, height 0.4s ease-in-out;
      &.spotify-open {
        height: calc(var(--app-height) - 287px - 14vw);
      }
    }

    &__title-wrapper {
      width: calc(100% - 21px);
    }

    &__history-wrapper{
      width: calc(100% + 1px);
    }

    &__content-outer-wrapper {
      width: calc(100% - 21px);
    }

    &__connection-wrapper {
      margin-bottom: 10px;
    }

    &__icon-wrapper {
      width: calc(4vw + 12px);
      height: calc(4vw + 12px);
    }

    &__connection-track-wrapper {
      margin-bottom: 15px;
      &::after{
        height: 4vw;
      }
    }

    &__connection-ref-wrapper{
      margin-bottom: 15px;
      &::after{
        height: calc(4vw + 12px);
        margin-top: 5px;
      }
    }

    &__connection-text {
      &.listen{
        height: calc(4vw + 12px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__connection-icon {
      width: calc(4vw + 12px);
      height: calc(4vw + 12px);
      padding-top: 0;
    }

    &__history-arrow{
      &.next{
        right: 5px;
      }
    }
  }
}