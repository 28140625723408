.list-view{

    &__close {
        right: 3px;
        z-index: 100;
      }

  &__outer-wrapper {
    width: calc(100vw - 52px - 30vw);
    height: calc(var(--app-height) - 3vw - 94px);

    position: fixed;
    top: calc(52px + 3vw);
    left: calc(-100vw);
    
    padding: 20px 0 0 20px;
    margin-left: 20px;

    display: flex;
    flex-direction: column;
    transition: height 0.4s ease-in-out, left 0.4s ease-in-out;
    z-index: 200;
    
    &.active {
      left: 0;
    }
    &.spotify-open {
      height: calc(var(--app-height) - 257px - 3vw);
    }
  }

  &__inner-wrapper {
    padding: 2px 17px 20px 0;
    overflow-y: auto;
    margin-top: calc(2vw + 30px);
    height: 1px;
    flex-grow: 1;
  }

  &__title-wrapper {
    width: calc(100% - 40px);
    padding: 15px 0 5px 0;
    border-bottom: 1px solid #000;
    position: absolute;
    top: 0;
    z-index: 10;
  }


  &__entry-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 7px 0 5px 0;
    border-bottom: 0.5px solid #000;
    &.active{
      background-color: #abff61;
    }
    @include hover{
      background-color: #DDD;
    }
  }

  &__entry-icon{
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 40px 0 5px;
    img{
      display: block;
      height: 15px;
      width: 15px;
    }
  }

  &__entry-title{
    flex-grow: 1;
    text-align: left;
  }

  &__entry-date{
    text-align: right;
    margin: 0 5px 0 15px;
  }

  &__sort-buttons-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__sort-button-wrapper{
    padding: 5px;
    text-transform: capitalize;
    position: relative;
    &.active{
      background-color: #abff61;
      padding-right: 20px;
    }
    @include hover{
      background-color: #DDD;
    }

    &.asc,
    &.desc{
      &::after{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        right: 5px;
        top: 6px;
        background-image:url('../../assets/icons/next.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    &.desc{
      &::after{
        transform: rotate(90deg);
      }
    }
    &.asc{
      &::after{
        transform: rotate(-90deg);
      }
    }
  }

 
}


@include mobile {

  .list-view{

    &__outer-wrapper {
      
      left: calc(-100vw - 40px);
      top: calc(10vw + 69px);
      width: calc(100% - 61px);
      height: calc(var(--app-height) - 14vw - 140px);
      z-index: 100;
      &.spotify-open {
        height: calc(var(--app-height) - 287px - 19vw);
      }
    }

    &__sort-buttons-wrapper{
      margin-top: 15px;
    }

    &__inner-wrapper {
      margin-top: calc(4vw + 40px);
    }

    &__entry-icon{
      margin: 0 20px 0 5px;
    }


  }
}